import { ResultElementProps } from '@/components/Search/components/LocationSelector/components/SearchInput/ResultElement';
import { SearchInputDto, TravellerGroup } from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';
import { FlightTypes } from '@/utils/types/FlightTypes';
import { Dayjs } from 'dayjs';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useGetParam } from './useGetParam';
import { useTrack } from './useTrack';
import { ProductType } from '@/components/Search/Search.types';

const getDestination = (
  destination: ResultElementProps,
  hotel: ResultElementProps
) => {
  const DESTINATION_VALUES = {
    Tag: { tag: destination.id },
    City: { cities: [parseInt(destination.id, 10)] },
    Hotel: { hotelids: [parseInt(destination.id, 10)] },
  };

  if (hotel) {
    return {
      ...DESTINATION_VALUES[destination.type],
      hotelids: [parseInt(destination.id, 10)],
    };
  }

  return DESTINATION_VALUES[destination.type] ?? undefined;
};

export function useCreateSearch() {
  const navigate = useNavigate();

  const track = useTrack();

  const getParam = useGetParam();

  const location = useLocation();

  const [brandConfig, createSearchId, createFlightSearchId] = useStore(
    (store) => [
      store.brandConfig,
      store.createSearchId,
      store.createFlightSearchId,
    ]
  );
  const {
    businessProfileIdDP,
    businessProfileIdF,
    businessProfileIdH,
    hasSSO,
  } = brandConfig.brand;

  const currency = brandConfig.brand.currency || 'EUR';

  const getSearchId = async ({
    departure,
    destination,
    hotel,
    dateRange,
    withHotel,
    travellerGroups,
    flightType,
    productType,
  }: {
    departure: ResultElementProps;
    destination: ResultElementProps;
    hotel?: ResultElementProps;
    dateRange: {
      from?: Dayjs;
      to?: Dayjs;
    };
    withHotel: boolean;
    travellerGroups: TravellerGroup[];
    flightType?: FlightTypes;
    productType?: string;
  }) => {
    const startDate = dateRange.from?.format('YYYY-MM-DD');

    const endDate = dateRange.to?.format('YYYY-MM-DD');

    switch (productType) {
      case ProductType.DP:
        const flightHotelSearchRequest: SearchInputDto = {
          businessProfileId: businessProfileIdDP,
          departure: {
            airports: [departure.code],
          },
          destination: getDestination(destination, hotel),
          interval: {
            start: {
              date: startDate,
            },
            end: {
              date: endDate,
            },
          },
          travellerGroups,
          currency,
        };

        return await createSearchId(flightHotelSearchRequest);

      case ProductType.H:
        const hotelSearchRequest = {
          businessProfileId: businessProfileIdH,
          departure: {},
          destination: getDestination(destination, hotel),
          interval: {
            start: {
              date: startDate,
            },
            end: {
              date: endDate,
            },
          },
          travellerGroups,
          currency,
        };
        return await createSearchId(hotelSearchRequest);

      case ProductType.F:
        const adultsCount = travellerGroups.reduce((acc, curr) => {
          acc = acc + curr.adults;
          return acc;
        }, 0);

        const minorsCount = travellerGroups.reduce((acc, curr) => {
          acc = acc.concat(curr.childrenAges);
          return acc;
        }, []);

        const childrenCount = minorsCount.filter((e) => e >= 2).length;
        const infantsCount = minorsCount.filter((e) => e < 2).length;
        const flightSearchRequest = {
          businessProfile: businessProfileIdF,
          departure: departure?.code,
          arrival: destination?.code,
          interval: {
            start: startDate,
            end: flightType === FlightTypes.R ? endDate : undefined,
          },
          travellers: {
            adults: `${adultsCount}`,
            children: `${childrenCount}`,
            infants: `${infantsCount}`,
          },
          currency,
        };
        return await createFlightSearchId(flightSearchRequest);

      default:
        return undefined;
    }
  };

  const createSearch = async ({
    departure,
    destination,
    hotel,
    dateRange,
    withHotel,
    travellerGroups,
    flightType,
    productType,
  }: {
    departure: ResultElementProps;
    destination: ResultElementProps;
    hotel?: ResultElementProps;
    dateRange: {
      from?: Dayjs;
      to?: Dayjs;
    };
    productType?: string;
    withHotel: boolean;
    travellerGroups: TravellerGroup[];
    flightType?: FlightTypes;
  }) => {
    const searchId = await getSearchId({
      productType,
      departure,
      destination,
      hotel,
      dateRange,
      withHotel,
      travellerGroups,
      flightType,
    }).then((e) => {
      track({
        eventName: 'created.search',
        logLevel: 'info',
        presearchProduct: productType,
      });

      return e;
    });

    const adultsList = travellerGroups.map((group) => group.adults).join(';');

    const childrenList = travellerGroups
      .map((group) =>
        group.childrenAges.length === 0 ? 'none' : group.childrenAges
      )
      .join(';');

    const aToken = hasSSO ? { a: getParam('a') } : undefined;

    const hasDeparture = departure && productType !== 'H';

    const navigateQueryParams = {
      product: productType,
      searchId: searchId,
      departure: hasDeparture ? departure.id.toLocaleLowerCase() : undefined,
      departureType: hasDeparture ? departure.type : undefined,
      departureName: hasDeparture
        ? departure.location || departure.name
        : undefined,
      destinationId: destination.code.toLocaleLowerCase(),
      destinationType: destination.type,
      destinationName: destination.location || destination.name,
      hotelId: hotel ? hotel?.code?.toLocaleLowerCase() : undefined,
      hotelName: hotel ? hotel?.name : undefined,
      outboundDate: dateRange.from?.format('YYYYMMDD'),
      returnDate: dateRange.to?.format('YYYYMMDD'),
      adults: adultsList,
      mealPlan: 'ALL_MEAL_PLANS',
      freeCancellation: 'false',
      sort: 'RECOMMENDED,true',
      page: 1,
      childrenAges: childrenList,
      ...aToken,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(navigateQueryParams).filter(
        ([, value]) => value !== undefined
      )
    );

    navigate({
      pathname: `/${productType}`,
      search: `?${createSearchParams({
        ...filteredParams,
      })}`,
    });

    if (location.pathname !== '/') {
      window.location.reload();
    }
  };

  return createSearch;
}
