import { useStore } from '@/store/useStore';
import { BookingType, useBookingType } from '../useBookingType';
import { LandingObject } from './types/LandingObject';
import { usePushPlatformData } from './usePushPlatformData';
import { getDeviceType } from './utils/getDeviceType';
import { mapObjectToPush } from './utils/mapObjectToPush';

export const usePushLandingData = () => {
  const { pushLanding } = usePushPlatformData();
  const brandConfig = useStore((state) => state.brandConfig);
  const { brandBase, currency } = brandConfig.brand;
  const { language, country } = brandConfig;
  const deviceType = getDeviceType();
  const bookingType = useBookingType();
  const locale = `${language}-${country}`;
  const { businessProfileIdDP, businessProfileIdF, businessProfileIdH } =
    brandConfig.brand;

  const getBP = () => {
    switch (bookingType) {
      case BookingType.Flight:
        return businessProfileIdF;
      case BookingType.FlightAndHotel:
        return businessProfileIdDP;
      case BookingType.Hotel:
        return businessProfileIdH;
      default:
        return undefined;
    }
  };

  const pushlandingData = () => {
    try {
      const newObject: LandingObject = mapObjectToPush({
        transactionAffiliation: getBP(),
        step: 'hp',
        brandBase,
        bookingType,
        locale,
        currency,
        deviceType,
      });

      pushLanding(newObject);
    } catch (e) {
      console.log('Error creating platform data object', e);
    }
  };

  return pushlandingData;
};
