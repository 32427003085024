/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DealDto } from '../models/DealDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DealsService {

    /**
     * @returns DealDto Success
     * @throws ApiError
     */
    public static getApiServicesAppDealsGetDealsByBp({
        businessProfile,
    }: {
        businessProfile?: string,
    }): CancelablePromise<Array<DealDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/Deals/GetDealsByBP',
            query: {
                'businessProfile': businessProfile,
            },
        });
    }

}
