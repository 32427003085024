import { useStore } from '@/store/useStore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import type {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';
import * as React from 'react';

export function useMuiConfig() {
  const brandConfig = useStore((state) => state.brandConfig);

  const { primaryColor, secondaryColor, accentColor } = brandConfig.theme;

  return React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: primaryColor,
          },
          secondary: {
            main: secondaryColor,
          },
          info: {
            main: '#0078d4',
          },
        },
        typography: { fontFamily: 'Open Sans' },
        components: {
          MuiLoadingButton: {
            styleOverrides: {
              root: {
                boxShadow: 'unset',
                '&:hover': {
                  boxShadow: 'unset',
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                height: '40px',
                textTransform: 'unset',
                fontSize: '14px',
                fontWeight: 600,
                borderRadius: '4px',
                color: 'white',
                backgroundColor: accentColor,
              },
            },
          },
          MuiSwitch: {
            defaultProps: {
              disableRipple: true,
              color: 'primary',
            },
            styleOverrides: {
              thumb: {
                height: '20px',
                width: '20px',
                backgroundColor: '#fff',
                boxShadow:
                  'rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 0px 2px, rgba(0, 0, 0, 0.04) 0px 0px 1px',
              },
              track: {
                height: '16px',
                width: '32px',
                borderRadius: '30px',
                opacity: '1 !important',
                backgroundColor: '#eaeaeb',
              },
              root: {
                alignItems: 'center',
              },
              switchBase: {
                '&:hover': {
                  backgroundColor: 'unset !important',
                },
              },
            },
          },
          MuiCheckbox: {
            styleOverrides: { root: { padding: '0px' } },
            defaultProps: {
              disableFocusRipple: true,
              disableTouchRipple: true,
              disableRipple: true,
            },
          },
          MuiTextField: {
            defaultProps: {
              variant: 'filled',
            },
            styleOverrides: {
              root: {
                '& label': {
                  color: '#74747c !important',
                  paddingLeft: '8px',
                },
                '& p': {
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#74747c',
                  marginLeft: '16px',
                },
                '& .Mui-disabled': {
                  backgroundColor: '#f5f5f5 !important',
                  borderRadius: '8px',
                  cursor: 'not-allowed',
                },
                '& div': {
                  backgroundColor: '#f5f5f5 !important',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                    borderBottom: 'unset',
                    // boxShadow: '0 0 0 3px #babac0',
                    transition: 'box-shadow 0.1s ease-in-out 0s',
                  },
                  '&:before': {
                    borderBottom: 'unset !important',
                  },
                  '&:after': {
                    borderBottom: 'unset',
                  },
                  '&:focus-within': {
                    boxShadow: '0 0 0 1px #3c3c3c',
                  },
                  input: {
                    paddingLeft: '18px',
                  },
                  '& .Mui-error': {
                    '&:focus-within': {
                      boxShadow: 'unset !important',
                    },
                  },
                  '& .Mui-disabled': {
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    cursor: 'not-allowed',
                  },
                },
                p: {
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#74747c',
                  marginLeft: '16px',
                },
                '& .Mui-error': {
                  '& input': {
                    boxShadow: '0 0 0 1px #f2142a !important',
                    borderRadius: '8px',
                    '&:focus-within': {
                      border: 'unset',
                    },
                    '&:focus': {
                      border: 'unset',
                    },
                    border: 'unset',
                  },
                  '&:hover': {
                    boxShadow: 'unset',
                  },
                },
              },
            },
          },

          MuiSelect: {
            defaultProps: {
              variant: 'filled',
              disableUnderline: true,
              IconComponent: KeyboardArrowDownIcon,
            },
            styleOverrides: {
              root: {
                borderRadius: '8px',
                '& .Mui-disabled': {
                  backgroundColor: '#f5f5f5',
                  borderRadius: '8px',
                  cursor: 'not-allowed',
                },
                '& .MuiSelect-select': {
                  '& :hover': {
                    backgroundColor: 'unset !important',
                  },
                },
              },
            },
          },
          MuiAlert: {
            styleOverrides: {
              root: {
                '& .MuiAlert-icon': {
                  display: 'flex',
                  alignItems: 'center',
                },
                borderRadius: '8px',
                padding: '8px 16px',
              },
            },
            variants: [
              {
                props: (props) => props.severity === 'info',
                style: {
                  color: '#0078d4',
                  backgroundColor: '#ecf7fe',
                },
              },
              {
                props: (props) => props.severity === 'warning',
                style: {
                  color: '#ff4800',
                  backgroundColor: '#fff8eb',
                },
              },
            ],
          },
        },
      }),
    []
  );
}
