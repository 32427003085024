import {
  ProposalsResponseAdvancedDto,
  TransportResponseAdvanceUpdated,
} from '@/services/codegen-romulo';
import dayjs from 'dayjs';
import { ResultsSearchParamsData } from '../types/MappingTypes';
import { SearchType } from '../types/SearchTypes';
import { calculateTripDuration } from './calculateTripDuration';

export function getSearch({
  bookingType,
  paramsData,
  hdpProposals,
  fProposals,
}: {
  bookingType: string;
  paramsData: ResultsSearchParamsData;
  hdpProposals: ProposalsResponseAdvancedDto;
  fProposals: TransportResponseAdvanceUpdated;
}): SearchType {
  const {
    numberOfTravellers,
    outboundDate,
    returnDate,
    departure,
    departureName,
    departureType,
    destinationId,
    destinationName,
    destinationType,
    searchId,
  } = paramsData;

  const departureDateDayjs = dayjs(outboundDate, 'YYYYMMDD');
  const returnDateDayjs = !!returnDate
    ? dayjs(returnDate, 'YYYYMMDD')
    : undefined;

  const numberOfGuests =
    numberOfTravellers.adultNumber +
    numberOfTravellers.childrenNumber +
    numberOfTravellers.infantNumber;

  const departureWeekDay = departureDateDayjs?.day();
  const returnWeekDay = returnDateDayjs?.day();

  const tripDuration =
    !!departureDateDayjs && !!returnDateDayjs
      ? calculateTripDuration(
          departureDateDayjs.format(),
          returnDateDayjs.format()
        )
      : 1;
  const advancedBooking = departureDateDayjs.diff(dayjs());

  const firstTransportHDP =
    bookingType !== 'Flight'
      ? hdpProposals?.search?.transports?.[0]
      : undefined;
  const firstTransportF =
    bookingType === 'Flight' ? fProposals?.proposals[0] : undefined;
  const staticDataHDP =
    bookingType !== 'Flight' ? hdpProposals?.staticData : undefined;
  const staticDataF =
    bookingType === 'Flight' ? fProposals?.staticData : undefined;

  const destinationAirport =
    bookingType !== 'Flight'
      ? firstTransportHDP?.outbound?.arrivalId
        ? staticDataHDP?.airports?.find(
            (airport) => airport.id === firstTransportHDP.outbound?.arrivalId
          )
        : undefined
      : staticDataF.airports[firstTransportF.transport.ways[0].arrivalId];
  const originAirport =
    bookingType !== 'Flight'
      ? firstTransportHDP?.outbound?.departureId
        ? staticDataHDP?.airports?.find(
            (airport) => airport.id === firstTransportHDP.outbound?.departureId
          )
        : undefined
      : staticDataF.airports[firstTransportF.transport.ways[0].departureId];

  const destinationCountry =
    bookingType !== 'Flight'
      ? firstTransportHDP
        ? destinationAirport?.countryId
          ? staticDataHDP?.countries?.find(
              (country) => country.id === destinationAirport.countryId
            )
          : undefined
        : hdpProposals.staticData.countries[0]
      : undefined;

  const originCountry =
    bookingType !== 'Flight'
      ? originAirport?.countryId
        ? staticDataHDP?.countries?.find(
            (country) => country.id === originAirport.countryId
          )
        : undefined
      : undefined;

  const destinationContinent =
    bookingType !== 'Flight'
      ? firstTransportHDP
        ? destinationCountry?.continentId
          ? staticDataHDP?.continents?.find(
              (continent) => continent.id === destinationCountry.continentId
            )
          : undefined
        : hdpProposals.staticData.continents[0]
      : undefined;
  const originContinent =
    bookingType !== 'Flight'
      ? originCountry?.continentId
        ? staticDataHDP?.continents?.find(
            (continent) => continent.id === originCountry.continentId
          )
        : undefined
      : undefined;

  const routeType =
    originCountry && destinationCountry
      ? originCountry?.id === destinationCountry?.id
        ? 'domestic'
        : originContinent?.id === destinationContinent?.id
          ? 'continental'
          : 'intercontinental'
      : undefined; // TODO For only flight don't have continents

  return {
    adults: numberOfTravellers.adultNumber,
    children: numberOfTravellers.childrenNumber,
    infants: numberOfTravellers.infantNumber,

    originCode: departure || undefined,
    originName: departureName || undefined,
    originType: departureType,
    destinationCode: destinationId || undefined,
    destinationName: destinationName || undefined,
    destinationType: destinationType,
    routeType: routeType,
    duration: Number(tripDuration),
    numberOfGuests: numberOfGuests,
    quantity: numberOfTravellers.numberOfRooms.toString(),
    searchId,
    advancedBooking: advancedBooking,
    departureWeekDay: departureWeekDay,
    originContinentCode: originContinent?.id || undefined,
    originContinentName: originContinent?.label || undefined,
    originCountryName: originCountry?.label || undefined,
    returnWeekDay: returnWeekDay,
    childrenAge: numberOfTravellers.allChildrenAges
      ?.filter((e) => e >= 2)
      .join(','),
    infantsAge: numberOfTravellers.allChildrenAges
      ?.filter((e) => e < 2)
      .join(','),

    destinationCountryCode: destinationCountry?.id || undefined,
    destinationCountryName: destinationCountry?.label || undefined,
    destinationContinentCode: destinationContinent?.id || undefined,
    destinationContinentName: destinationContinent?.label || undefined,

    departureDate: departureDateDayjs?.format('YYYYMMDD'),
    returnDate: returnDateDayjs?.format('YYYYMMDD'),
    roundTrip: firstTransportHDP?.inbound ? 'RT' : 'OW',
  };
}
