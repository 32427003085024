import { useGetParam } from '@/hooks/useGetParam';
import { useGetTravellerGroups } from '@/hooks/useGetTravellerGroups';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';

export function useGetInfoData(showSearchResume: boolean) {
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const locale = brandConfig.language || 'en';

  const { t } = useTranslate();

  const getParam = useGetParam();

  const { peopleNumber } = useGetTravellerGroups();

  const aToken = getParam('a');

  const datefrom = getParam('outboundDate');

  const dateto = getParam('returnDate');

  const departureName = getParam('departureName');

  const destinationName = getParam('destinationName');

  const dateText = showSearchResume
    ? dateto === null
      ? `${dayjs(datefrom, 'YYYYMMDD').locale(locale).format('DD MMM')}`
      : `${dayjs(datefrom, 'YYYYMMDD').locale(locale).format('DD MMM')} - 
  ${dayjs(dateto, 'YYYYMMDD').locale(locale).format('DD MMM')}`
    : '';

  const locationsText = showSearchResume
    ? departureName !== 'null' && !!departureName
      ? `${departureName} - 
  ${destinationName}`
      : `${destinationName}`
    : '';

  const passengersText = `${peopleNumber} ${(peopleNumber > 1
    ? t('search.travellers')
    : t('search.traveller')
  ).toLowerCase()}`;

  const passengersFlightsText = `${peopleNumber} ${(peopleNumber > 1
    ? t('search.passengers')
    : t('search.passenger')
  ).toLowerCase()}`;

  return {
    dateText,
    locationsText,
    passengersText,
    passengersFlightsText,
    aToken,
  };
}
