import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TextField as MuiTextField } from '@mui/material';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const ResultsContainer = styled.div`
  background-color: #fff;
  padding: 8px 0px 8px 8px;
  border-radius: 8px;
  margin-top: 4px;
  box-shadow:
    rgba(0, 0, 0, 0.04) 0px 24px 32px,
    rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.04) 0px 4px 8px,
    rgba(0, 0, 0, 0.04) 0px 0px 10px;
  min-width: 450px;
  width: 450px;
  position: absolute;
  z-index: 10;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const ListContainer = styled.div`
  max-height: 550px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgb(130, 130, 137) rgb(255, 255, 255);
  padding-right: 8px;
`;

export const TextField = styled(MuiTextField)`
  & div {
    background-color: white !important;
    border-radius: 8px 8px 0px 0px;
    &:focus {
      background-color: white !important;
    }
    &:hover {
      background-color: white !important;
    }
    &:focus-within {
      box-shadow: unset;
    }
  }
`;

export const MobileListContainer = styled.div`
  border-top: 1px solid #f5f5f5;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgb(130, 130, 137) rgb(255, 255, 255);
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  padding: 8px;
`;

export const ContentContainer = styled.div`
  display: flex;
`;

export const Icon = styled.div`
  align-items: center;
  background: rgb(245, 245, 245);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  height: 44px;
  justify-content: center;
  margin-right: 12px;
  padding: 12px;
  width: 44px;
`;

export const SubElement = styled.div`
  align-items: center;
  display: flex;
  height: 44px;
  padding: 12px;
  margin-right: 12px;
  width: 44px;
`;

export const Check = styled.div`
  align-items: center;
  display: flex;
  height: 44px;
  padding: 12px;
  width: 44px;
  margin-left: auto;
  color: rgb(0, 99, 190);
`;

export const ResultElement = styled.div<{
  $disabled?: boolean;
  $preSelected?: boolean;
}>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: rgb(60, 60, 60);
  background: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;

  ${({ $preSelected }) =>
    $preSelected &&
    css`
      background-color: rgb(245, 245, 245);
    `}

  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: 0.35;
          cursor: unset;
        `
      : css`
          :hover {
            color: rgb(60, 60, 60);
            background-color: rgb(245, 245, 245);
            & .icon-container {
              background-color: #fff;
            }
          }
        `}
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
`;
export const Subtitle = styled.span`
  display: block;
  color: rgb(130, 130, 137);
  font-size: 14px;
  font-weight: 400;
`;

export const Error = styled.span`
  width: 100%;
  display: block;
  position: absolute;
  overflow: hidden;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
  z-index: 1;
  margin-top: 2px;
  background-color: rgb(255, 233, 235);
  color: rgb(221, 0, 22);
`;
