import { eventName } from './commonValues';
import { Input, Step, StepObjectMap } from '../types/MappingTypes';
import { getPageInfo } from './getPageInfo';
import { getUserInfo } from './getUserInfo';
import { getSearch } from './getSearch';
import { getProductInfo } from './getProductInfo';
import { getTransactionInfo } from './getTransactionInfo';

export function mapObjectToPush<T extends Step>(
  props: Input<T>
): StepObjectMap[T] {
  const event = eventName;

  const page = getPageInfo({
    step: props.step,
    brandBase: props.brandBase,
    bookingType: props.bookingType,
    locale: props.locale,
  });

  const user = getUserInfo({
    step: props.step,
    currency: props.currency,
    deviceType: props.deviceType,
    accountId: props.step === 'payment' ? 'null' : undefined, // TODO We don't have it, we can create in the front, check it with LM
    hashedEmail:
      props.step === 'thankYou'
        ? props.cartDetail.voucherInfo.authorizedEmailHashes[0]
        : undefined,
  });

  const transactionAffiliation = props.transactionAffiliation;

  const results =
    props.step === 'results'
      ? {
          totalNumberOfResults:
            props.bookingType !== 'Flight'
              ? props.hdpProposals?.facets.totalProposals
              : props.fProposals.facets.totalProposals,
          flashSales:
            props.bookingType !== 'Flight'
              ? props.hdpProposals?.staticData.promotions.filter(
                  (e) => e.type === 'FLASH_SALE'
                ).length
              : undefined,
          cheapestPriceInPage: (props.bookingType !== 'Flight'
            ? props.hdpProposals.sorting.sorters.price.metadata.startingPrice
                .std // TODO add cheapest price for flight
            : 0 / 100
          ).toString(),
        }
      : undefined;

  const search =
    props.step === 'results' &&
    getSearch({
      bookingType: props.bookingType,
      paramsData: props.paramsData,
      hdpProposals:
        props.bookingType !== 'Flight' ? props.hdpProposals : undefined,
      fProposals: props.bookingType === 'Flight' ? props.fProposals : undefined,
    });

  const product =
    (props.step === 'details' ||
      props.step === 'payment' ||
      props.step === 'thankYou') &&
    getProductInfo({
      step: props.step,
      numberOfTravellers: props.numberOfTravellers,
      pricingProposals:
        (props.step === 'details' ||
          props.step === 'payment' ||
          props.step === 'thankYou') &&
        props.pricingProposals,
      cartDetail:
        (props.step === 'payment' || props.step === 'thankYou') &&
        props.cartDetail,
      bookingType: props.bookingType,
    });

  const transactionInfo =
    props.step === 'thankYou' &&
    getTransactionInfo({
      cartDetail: props.cartDetail,
      paymentMethod: props.paymentMethod,
    });

  switch (props.step) {
    case 'hp':
      return {
        event,
        page,
        user,
        transactionAffiliation,
      } as StepObjectMap['hp'];
    case 'results':
      return {
        event,
        page,
        user,
        transactionAffiliation,
        results,
        search,
      } as StepObjectMap['results'];
    case 'details':
      return { event, page, product } as StepObjectMap['details'];
    case 'payment':
      return {
        event,
        page,
        user,
        transactionAffiliation,
        transactionId: props.transactionId,
        product,
      } as StepObjectMap['payment'];
    case 'thankYou':
      return {
        event,
        page,
        user,
        transactionAffiliation,
        product,
        ...transactionInfo,
      } as StepObjectMap['thankYou'];
    default:
      throw new Error('Invalid step');
  }
}
