import { Close } from '@mui/icons-material';
import { DialogActions, IconButton } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslate } from '@tolgee/react';
import * as React from 'react';
import * as Styled from './Modal.styled';
import { ModalProps } from './Modal.types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function Modal({
  title,
  children,
  isModalOpen,
  fullHeight,
  minHeight,
  maxWitdh,
  onCloseModal,
  Footer,
  closable,
}: ModalProps) {
  const { t } = useTranslate();
  return (
    <Styled.Dialog
      open={isModalOpen}
      $fullheight={fullHeight?.toString()}
      $minHeight={minHeight}
      $maxWitdh={maxWitdh}
      TransitionComponent={Transition}
      onClose={onCloseModal}
    >
      {title && (
        <>
          <DialogTitle sx={{ textAlign: 'center' }}>{t(title)}</DialogTitle>
          {closable && (
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              disableRipple
              sx={{
                position: 'absolute',
                left: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          )}
        </>
      )}
      <DialogContent sx={{ backgroundColor: 'rgb(245, 245, 245)' }}>
        {children}
      </DialogContent>
      {!!Footer && (
        <DialogActions>
          <Footer />
        </DialogActions>
      )}
    </Styled.Dialog>
  );
}
