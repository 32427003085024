import { CartDetailResponseDto } from '@/services/codegen-romulo';
import dayjs from 'dayjs';
import { TripObjectMap } from '../types/MappingTypes';
import { calculateSegmentDuration } from './calculateSegmentDuration';
import { formatTime } from './formatTime';
import { getDetailedTransportEndpoints } from './getTransportOriginDestination';
import { getWeekDay } from './getWeekDay';

export function getFlightTrip<T extends 'payment' | 'thankYou'>({
  cartDetail,
}: {
  step: T;
  cartDetail: CartDetailResponseDto;
}): TripObjectMap[T] {
  const itinerary = cartDetail.products.find((e) => e.type === 'FLIGHT');

  const outboundEndpoints = getDetailedTransportEndpoints(
    itinerary?.detail?.itinerary?.outboundTransport
  );

  const inboundEndpoints = getDetailedTransportEndpoints(
    itinerary?.detail?.itinerary?.inboundTransport
  );

  const outboundFlightNames = outboundEndpoints?.allStops.map(
    (stop) => stop.departure.company.transportCode
  );
  const inboundFlightNames = inboundEndpoints?.allStops.map(
    (stop) => stop.departure.company.transportCode
  );

  const flightNames = outboundFlightNames?.concat(inboundFlightNames).join('|');

  const routeType =
    outboundEndpoints && inboundEndpoints
      ? outboundEndpoints.origin.destinationStation.country.isoCode ===
        inboundEndpoints.origin.destinationStation.country.isoCode
        ? 'domestic'
        : outboundEndpoints.origin.destinationStation.continent.continentId ===
            inboundEndpoints.origin.destinationStation.continent.continentId
          ? 'continental'
          : 'intercontinental'
      : undefined;

  const basicTrip = {
    class: outboundEndpoints?.origin.reservationInfo.cabinClass,
    departureDate: outboundEndpoints?.origin.departureDateTime,
    duration: calculateSegmentDuration(
      outboundEndpoints?.origin?.departureDateTime,
      inboundEndpoints?.destination?.destinationDateTime ||
        outboundEndpoints?.destination?.destinationDateTime
    ),
    name: flightNames,
    outboundArrivalHour: outboundEndpoints?.destination.destinationDateTime
      ? dayjs(outboundEndpoints?.destination.destinationDateTime).format(
          'HH:mm'
        )
      : undefined,
    outboundDuration: calculateSegmentDuration(
      outboundEndpoints?.origin?.departureDateTime,
      outboundEndpoints?.destination?.destinationDateTime
    ),
    outboundOriginCode: outboundEndpoints?.origin.departureStation.iataCode,
    outboundOriginName: outboundEndpoints?.origin.departureStation.cityName,
    outboundProviderId: outboundEndpoints?.origin.company.id,
    outboundProviderName: outboundEndpoints?.origin.company.name,
    outboundStops: outboundEndpoints?.allStops.length,
    returnArrivalHour: inboundEndpoints?.destination.destinationDateTime
      ? dayjs(inboundEndpoints?.destination.destinationDateTime).format('HH:mm')
      : undefined,
    returnDate: inboundEndpoints?.origin.departureDateTime,
    returnDepartureHour: inboundEndpoints?.origin.departureDateTime,
    returnDepartureWeekDay: inboundEndpoints?.origin.departureDateTime
      ? dayjs(inboundEndpoints?.origin.departureDateTime).day()
      : undefined, // TODO,
    returnDestinationCode:
      inboundEndpoints?.destination.departureStation.iataCode,
    returnProviderId: inboundEndpoints?.origin.company.id,
    returnProviderName: inboundEndpoints?.origin.company.name,
    returnStops: inboundEndpoints?.allStops.length, // TODO,
    roundTrip: !!inboundEndpoints,
    destinationCountryCode:
      outboundEndpoints?.destination.destinationStation.country.isoCode,
    destinationCountryName:
      outboundEndpoints?.destination.destinationStation.country.name,
    originCountryCode:
      outboundEndpoints?.origin.departureStation.country.isoCode,
    originCountryName: outboundEndpoints?.origin.departureStation.country.name,
    destinationContinentCode:
      outboundEndpoints?.destination.destinationStation.continent.continentId,
    destinationContinentName:
      outboundEndpoints?.destination.destinationStation.continent.name,
    originContinentCode:
      outboundEndpoints?.origin.departureStation.continent.continentId,
    originContinentName:
      outboundEndpoints?.origin.departureStation.continent.name,
    outboundDestinationMacroCode:
      outboundEndpoints?.destination.destinationStation.iataCode,
    returnDestinationMacroName:
      inboundEndpoints?.destination.destinationStation.stationName,
    returnOriginMacroCode:
      inboundEndpoints?.destination.departureStation.iataCode,
    returnOriginMacroName:
      inboundEndpoints?.destination.departureStation.stationName,
    returnProviderType: inboundEndpoints?.origin?.company?.companyType,
    routeType: routeType,
    outboundDepartureHour: formatTime(
      outboundEndpoints?.origin?.departureDateTime
    ),
    outboundDepartureWeekDay: getWeekDay(
      outboundEndpoints?.origin?.departureDateTime
    ),
    outboundDestinationCode:
      outboundEndpoints?.destination?.destinationStation?.iataCode,
    outboundDestinationMacroName:
      outboundEndpoints?.destination?.destinationStation?.stationName,
    outboundDestinationName:
      outboundEndpoints?.destination?.destinationStation?.cityName,
    outboundOriginMacroCode:
      outboundEndpoints?.origin?.departureStation?.cityGroup,
    outboundOriginMacroName:
      outboundEndpoints?.origin?.departureStation?.stationName,
    outboundProviderType: outboundEndpoints?.origin?.company?.companyType,
    returnDestinationMacroCode:
      inboundEndpoints?.destination?.destinationStation?.cityGroup,
    returnDestinationName:
      inboundEndpoints?.destination?.destinationStation?.cityName,
    returnDuration: calculateSegmentDuration(
      inboundEndpoints?.origin?.departureDateTime,
      inboundEndpoints?.destination?.destinationDateTime
    ),
    returnOriginCode: inboundEndpoints?.origin?.departureStation?.iataCode,
    returnOriginName: inboundEndpoints?.origin?.departureStation?.cityName,
  };

  return basicTrip;
}
