import {
  CartDetailResponseDto,
  ProposalTransport,
  StaticData,
} from '@/services/codegen-romulo';
import dayjs from 'dayjs';
import { TripObjectMap } from '../types/MappingTypes';
import { calculateSegmentDuration } from './calculateSegmentDuration';
import { formatTime } from './formatTime';
import { getDetailedTransportEndpoints } from './getTransportOriginDestination';
import { getWeekDay } from './getWeekDay';

export function getPricingTrip<T extends 'details' | 'payment' | 'thankYou'>({
  step,
  transport,
  staticData,
  cartDetail,
}: {
  step: T;
  staticData: StaticData;
  transport: ProposalTransport;
  cartDetail: CartDetailResponseDto;
}): TripObjectMap[T] {
  const findAirportInfo = (airportId: string | null | undefined) => {
    const airport = staticData?.airports?.find((a) => a.id === airportId);
    const country = staticData?.countries?.find(
      (c) => c.id === airport?.countryId
    );
    const continent = staticData?.continents?.find(
      (c) => c.id === country?.continentId
    );
    return { airport, country, continent };
  };

  const outboundDepartureInfo = findAirportInfo(
    transport?.outbound?.departureId
  );
  const outboundArrivalInfo = findAirportInfo(transport?.outbound?.arrivalId);
  const inboundDepartureInfo = findAirportInfo(transport?.inbound?.departureId);
  const inboundArrivalInfo = findAirportInfo(transport?.inbound?.arrivalId);

  const outboundAirline = staticData?.airlines?.find(
    (a) => a.id === transport?.outbound?.supplier?.id
  );
  const inboundAirline = staticData?.airlines?.find(
    (a) => a.id === transport?.inbound?.supplier?.id
  );

  const outboundFlightNames = transport.outbound.segments.map(
    (stop) => stop.supplier.carrierId
  );
  const inboundFlightNames = transport.inbound.segments.map(
    (stop) => stop.supplier.carrierId
  );

  const flightNames = outboundFlightNames.concat(inboundFlightNames).join('|');

  const basicTrip = {
    class: transport.outbound?.classAvailability,
    departureDate: transport.outbound?.isoZonedDepartureDateTime,
    duration: transport.outbound?.durationInMinutes,
    name: flightNames,
    outboundArrivalHour: transport.outbound?.isoZonedArrivalDateTime
      ? dayjs(transport.outbound.isoZonedArrivalDateTime).format('HH:mm')
      : undefined,
    outboundDuration: transport.outbound?.durationInMinutes,
    outboundOriginCode: transport.outbound?.departureId,
    outboundOriginName: outboundDepartureInfo.airport?.label,
    outboundProviderId: transport.outbound?.supplier?.id,
    outboundProviderName:
      outboundAirline?.label || transport.outbound?.supplier?.operatorId,
    outboundStops: transport.outbound?.segments?.length
      ? transport.outbound.segments.length - 1
      : undefined,
    returnArrivalHour: transport.inbound?.isoZonedArrivalDateTime
      ? dayjs(transport.inbound.isoZonedArrivalDateTime).format('HH:mm')
      : undefined,
    returnDate: transport.inbound?.isoZonedDepartureDateTime,
    returnDepartureHour: transport.inbound?.isoZonedDepartureDateTime
      ? dayjs(transport.inbound.isoZonedDepartureDateTime).format('HH:mm')
      : undefined,
    returnDepartureWeekDay: transport.inbound?.isoZonedDepartureDateTime
      ? dayjs(transport.inbound.isoZonedDepartureDateTime).day()
      : undefined,
    returnDestinationCode: transport.inbound?.arrivalId,
    returnProviderId: transport.inbound?.supplier?.id,
    returnProviderName:
      inboundAirline?.label || transport.inbound?.supplier?.operatorId,
    returnStops: transport.inbound?.segments?.length
      ? transport.inbound.segments.length - 1
      : undefined,
    roundTrip: !!transport.inbound,
    destinationCountryCode: outboundArrivalInfo.country?.id,
    destinationCountryName: outboundArrivalInfo.country?.label,
    originCountryCode: outboundDepartureInfo.country?.id,
    originCountryName: outboundDepartureInfo.country?.label,
    destinationContinentCode: outboundArrivalInfo.continent?.id,
    destinationContinentName: outboundArrivalInfo.continent?.label,
    originContinentCode: outboundDepartureInfo.continent?.id,
    originContinentName: outboundDepartureInfo.continent?.label,
    outboundDestinationMacroCode: outboundArrivalInfo.airport?.id,
    returnDestinationMacroName: inboundArrivalInfo.airport?.label,
    returnOriginMacroCode: inboundDepartureInfo.airport?.id,
    returnOriginMacroName: inboundDepartureInfo.airport?.label,
    returnProviderType: transport.inbound?.transportType,
    routeType: transport.type,
  };

  const getExtras = () => {
    const itinerary = cartDetail.products.find((e) => e.type === 'FLIGHT');

    const outboundEndpoints = getDetailedTransportEndpoints(
      itinerary?.detail?.itinerary?.outboundTransport
    );
    const inboundEndpoints = getDetailedTransportEndpoints(
      itinerary?.detail?.itinerary?.inboundTransport
    );
    const tripExtras = {
      outboundDepartureHour: formatTime(
        outboundEndpoints.origin?.departureDateTime
      ),
      outboundDepartureWeekDay: getWeekDay(
        outboundEndpoints.origin?.departureDateTime
      ),
      outboundDestinationCode:
        outboundEndpoints.destination?.destinationStation?.iataCode,
      outboundDestinationMacroName:
        outboundEndpoints.destination?.destinationStation?.stationName,
      outboundDestinationName:
        outboundEndpoints.destination?.destinationStation?.cityName,
      outboundOriginMacroCode:
        outboundEndpoints.origin?.departureStation?.cityGroup,
      outboundOriginMacroName:
        outboundEndpoints.origin?.departureStation?.stationName,
      outboundProviderType: outboundEndpoints.origin?.company?.companyType,
      returnDestinationMacroCode:
        inboundEndpoints.destination?.destinationStation?.cityGroup,
      returnDestinationName:
        inboundEndpoints.destination?.destinationStation?.cityName,
      returnDuration: calculateSegmentDuration(
        inboundEndpoints.origin?.departureDateTime,
        inboundEndpoints.destination?.destinationDateTime
      ),
      returnOriginCode: inboundEndpoints.origin?.departureStation?.iataCode,
      returnOriginName: inboundEndpoints.origin?.departureStation?.cityName,
    };

    return tripExtras;
  };

  const extras = step !== 'details' && getExtras();

  switch (step) {
    case 'details':
      return basicTrip;
    case 'payment':
      return {
        ...basicTrip,
        ...extras,
      };
    case 'thankYou':
      return {
        ...basicTrip,
        ...extras,
      };
    default:
      return basicTrip;
  }
}
