type CurrencyDisplay = 'code' | 'symbol';

type Options = { minimumFractionDigits?: number };

type CustomSymbols = {
  [key: string]: string;
};

type NumberFormatPartTypes =
  | 'currency'
  | 'decimal'
  | 'fraction'
  | 'group'
  | 'infinity'
  | 'integer'
  | 'literal'
  | 'minusSign'
  | 'nan'
  | 'plusSign'
  | 'percentSign';

export type NumberFormatPart = {
  type: NumberFormatPartTypes;
  value: string;
};

const CURRENCIES_FOR_WHICH_WE_SHOW_THE_3_LETTERS_CODE = [
  'ARS',
  'AUD',
  'CAD',
  'USD',
  'DKK',
  'ISK',
  'NOK',
  'SEK',
];
const CUSTOM_SYMBOL: CustomSymbols = {
  CLP: 'CLP $',
  COP: 'COP $',
  MXN: 'MXN $',
  RON: 'Lei',
};

const CURRENCIES_FOR_CUSTOM_SYMBOL_TO_FORCE_3_LETTERS_CODE_FIRST =
  Object.keys(CUSTOM_SYMBOL);
const ALL_3_LETTERS_CODE_CURRENCIES =
  CURRENCIES_FOR_WHICH_WE_SHOW_THE_3_LETTERS_CODE.concat(
    CURRENCIES_FOR_CUSTOM_SYMBOL_TO_FORCE_3_LETTERS_CODE_FIRST
  );

const selectCurrencyDisplay = (formattingCurrency: string): CurrencyDisplay =>
  ALL_3_LETTERS_CODE_CURRENCIES.includes(formattingCurrency)
    ? 'code'
    : 'symbol';

const applyCustomSymbol = (
  formattedNumber: string,
  formattingCurrency: string
): string =>
  formattedNumber.replace(
    formattingCurrency,
    CUSTOM_SYMBOL[formattingCurrency] || formattingCurrency
  );

const isString = (number: number | string): number is string =>
  typeof number === 'string';

const createIntlFormatter = (
  locale: string,
  currency: string,
  minimumFractionDigits?: number
): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, {
    currency,
    minimumFractionDigits,
    style: 'currency',
    currencyDisplay: selectCurrencyDisplay(currency),
  });

const parse = (number: number | string): number =>
  isString(number) ? parseFloat(number) : number;

export default class CurrencyFormatter {
  private locale: string;

  private currency: string;

  private minimumFractionDigits?: number;

  constructor(locale: string, currency: string, options?: Options) {
    this.locale = locale.replace('_', '-');
    this.currency = currency;
    this.minimumFractionDigits = options && options.minimumFractionDigits;
  }

  /**
   * This method formats a number according to the locale and formatting options of this NumberFormat object.
   *
   * @param {(string|number)} number - A `Number` or `BigInt` or number representation in `String` to format.
   * @return {string} A formatted string with currency and amount.
   *
   * @example
   *
   *     const instance = new CurrencyFormatter('en-GB', 'EUR');
   *     instance.formatNumber(1000);
   */
  formatNumber(number: number | string, fractionDigits?: number): string {
    const value = parse(number);
    return applyCustomSymbol(
      createIntlFormatter(
        this.locale,
        this.currency,
        fractionDigits || this.minimumFractionDigits
      ).format(value),
      this.currency
    );
  }

  /**
   * This method formats a number according to the locale and formatting options of this NumberFormat object
   * and returns the largest integer less than or equal to a given number.
   *
   * @param {(string|number)} number - A `Number` or `BigInt` or number representation in `String` to format.
   * @return {string} A formatted string with currency and amount.
   *
   * @example
   *
   *     const instance = new CurrencyFormatter('en-GB', 'EUR');
   *     instance.formatNumberToFloor(1000);
   */
  formatNumberToFloor(number: number | string): string {
    const value = Math.floor(parse(number));
    const formattedValue = createIntlFormatter(
      this.locale,
      this.currency,
      0
    ).format(value);

    return applyCustomSymbol(formattedValue, this.currency);
  }

  /**
   * This method formats a number according to the locale and formatting options of this NumberFormat object
   * and returns the largest integer less than or equal to a given number.
   *
   * @param {(string|number)} number - A `Number` or `BigInt` or number representation in `String` to format.
   * @return {string} A formatted string with currency and amount.
   *
   * @example
   *
   *     const instance = new CurrencyFormatter('en-GB', 'EUR');
   *     instance.formatNumberToCeil(1000);
   */
  formatNumberToCeil(number: number | string): string {
    const value = Math.ceil(parse(number));
    const formattedValue = createIntlFormatter(
      this.locale,
      this.currency,
      0
    ).format(value);

    return applyCustomSymbol(formattedValue, this.currency);
  }

  /**
   * This method allows locale-aware formatting of strings produced by CurrencyFormatter.
   *
   * Please note, that it is not implemented on IE11,
   * where we added a polyfill that returns an array with just a literal object.
   *
   * @param {(string|number)} number - A `Number` or `BigInt` or number representation in `String` to format.
   * @return {object[]} An `Array` of objects containing the formatted number in parts.
   *
   * @example
   *
   *     const instance = new CurrencyFormatter('en-GB', 'EUR');
   *     instance.formatNumberToParts(1000);
   */
  formatNumberToParts(number: number | string): NumberFormatPart[] {
    const instance = createIntlFormatter(
      this.locale,
      this.currency,
      this.minimumFractionDigits
    );
    if (!instance.formatToParts) {
      return [
        {
          type: 'literal',
          value: this.formatNumber(number),
        },
      ];
    }
    const value = parse(number);
    const parts: Intl.NumberFormatPart[] = instance.formatToParts(value);
    return parts.map((part: Intl.NumberFormatPart): NumberFormatPart => {
      const numberFormatPart = {
        type: part.type,
        value: part.value,
      } as NumberFormatPart;
      return part.type === 'currency'
        ? (Object.assign(numberFormatPart, {
            value: applyCustomSymbol(part.value, this.currency),
          }) as NumberFormatPart)
        : numberFormatPart;
    });
  }
}
