import dayjs from 'dayjs';

export function calculateTripDuration(
  start?: string,
  end?: string
): string | undefined {
  if (!start || !end) return undefined;
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  return endDate.diff(startDate, 'day').toString();
}
