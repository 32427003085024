import dayjs, { Dayjs } from 'dayjs';
import { localeObject } from './localeObjects';

const ONE_MINUTE_IN_MS = 1000 * 60;
const ONE_DAY_IN_MS = ONE_MINUTE_IN_MS * 60 * 24;

export type Duration = {
  minutes: number;
  hours: number;
};

export const minutesToHours = (mins: number) => {
  if (mins < 0) {
    return '';
  }

  const hours: number = Math.floor(mins / 60);
  const minutes: number = mins % 60;

  const result = {
    hours: hours === 0 ? undefined : hours,
    minutes: minutes,
  };

  return result.hours
    ? `${result.hours}h ${result.minutes !== 0 ? `${result.minutes}m` : ''}`
    : `${result.minutes}m`;
};

export const getTimeInTheTimezone = (
  value: string,
  language: string = 'en',
  valueFormat?: string,
  isIsoZone?: boolean
) => {
  const offset = value?.match(/[+-]\d{2}:\d{2}$/);

  return offset && offset[0]
    ? dayjs(value, valueFormat)
        .locale(language, localeObject[language])
        .utcOffset(offset[0])
    : !isIsoZone
      ? dayjs(value, valueFormat).locale(language, localeObject[language])
      : dayjs(value, valueFormat)
          .locale(language, localeObject[language])
          .utc();
};

export const explodeDuration = (durationInMinutes: number): Duration => {
  const minutes = durationInMinutes % 60;
  const hours = Math.trunc(durationInMinutes / 60);

  return { minutes, hours };
};
// Format HH:MM
export const hoursToMinutes = (time: string): number => {
  return time?.split(':').reduce((acc, curr, currIndex) => {
    acc = currIndex === 0 ? acc + Number(curr) * 60 : acc + Number(curr);
    return acc;
  }, 0);
};

export const calcNightStays = (
  firstDate: string,
  secondDate: string
): number => {
  const differenceInMs =
    dateWithoutTime(anticipateDayIfBeforeGivenHour(secondDate, 2)).getTime() -
    dateWithoutTime(anticipateDayIfBeforeGivenHour(firstDate, 2)).getTime();
  const differenceInDays = Math.trunc(differenceInMs / ONE_DAY_IN_MS);

  return differenceInDays < 0 ? differenceInDays * -1 : differenceInDays;
};

function anticipateDayIfBeforeGivenHour(
  fullDate: string,
  hours: number
): string {
  const originalDate = new Date(fullDate);
  // we can ignore seconds and milliseconds here
  if (originalDate.getUTCHours() <= hours && originalDate.getUTCMinutes() === 0)
    originalDate.setDate(originalDate.getDate() - 1);
  return originalDate.toISOString();
}

function dateWithoutTime(fullDate: string): Date {
  return new Date(`${fullDate.split('T')[0]}T00:00:00.000`);
}

export const dayOfMonth = (date: string): number => {
  const day = date.split('T')[0].split('-')[1];
  return parseInt(day, 10);
};

export const calculateDifferenceInMinutes = (
  firstDate: string,
  secondDate: string
): number =>
  Math.trunc(
    (new Date(secondDate).getTime() - new Date(firstDate).getTime()) /
      ONE_MINUTE_IN_MS
  );

export const getHours = (fullDate: string): number => {
  const time = fullDate.split('T')[1];
  return parseInt(time.split(':')[0], 10);
};

export const calculateTimeFrames = (countDown) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export const formatHour = (dateTime: string): string => {
  const departureTime = new Date(dateTime);
  const departureMinute = departureTime.getMinutes();
  const departureHours = departureTime.getHours();
  const zeroPrefixForMinutes = departureMinute < 10 ? '0' : '';
  const zeroPrefixForHours = departureHours < 10 ? '0' : '';

  return `${zeroPrefixForHours}${departureHours}:${zeroPrefixForMinutes}${departureMinute}`;
};

export function getHoursString(e) {
  const duration = explodeDuration(e);
  return `${duration.hours < 10 ? `0${duration.hours}` : duration.hours}:${
    duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes
  }`;
}

export function getTImeString(e) {
  const duration = explodeDuration(e);
  return `${duration.hours}h ${duration.minutes}m`;
}
