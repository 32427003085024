import { CartDetailResponseDto } from '@/services/codegen-romulo';

export function getTransactionInfo({
  cartDetail,
  paymentMethod,
}: {
  cartDetail: CartDetailResponseDto;
  paymentMethod: string;
}) {
  return {
    transactionId: cartDetail.id,
    transactionPaymentMethodName: paymentMethod,
    transactionProducts: cartDetail.products.map((e) => ({
      category: e.type,
      name: e.id, // TODO We don't have some info
      price:
        e.type === 'HOTEL'
          ? e.detail.accommodation.cost.cost.value
          : e.cost.amount.value,
      quantity: 1,
      sku: e.id, // TODO We don't have some info
    })),
    transactionTax: cartDetail.receipt.paymentFees.value,
    transactionTotalEUR: 0, // TODO We only receive price in local currency
    transactionTotal: cartDetail.receipt.totalProductsCost.value,
    transactionTaxEUR: 0, // TODO We only receive price in local currency
    transactionVoucherCode: cartDetail.voucherInfo.code,
    transactionVoucherAmount: cartDetail.receipt.totalProductsCost.value,
  };
}
