/* eslint-disable react-hooks/exhaustive-deps */
import { DocumentTitle } from '@/components/DocumentTitle';
import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { Hero } from '@/components/Hero';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { getSearchParam } from '@/utils/getSearchParam';
import * as React from 'react';
import { Offers } from './Offers/Deals/Deals';
import { FavouriteDestinations } from './Offers/FavouriteDestinations/FavouriteDestinations';
import { TravelSearchInfo } from './TravelSearchInfo';
import { usePushLandingData } from '@/hooks/usePushPlatformData/usePushLandingData';

export function TravelSearch() {
  const [brandConfig /*, getDeals*/] = useStore((state) => [
    state.brandConfig,
    state.getDeals,
    state.deals,
  ]);

  const track = useTrack();
  const pushlandingData = usePushLandingData();

  React.useEffect(() => {
    pushlandingData();
  }, []);

  React.useEffect(() => {
    if (brandConfig.brand.businessProfileIdDP) {
      //getDeals(brandConfig.brand.businessProfileIdDP);
    }
  }, [brandConfig.brand.businessProfileIdDP]);

  React.useEffect(() => {
    if (brandConfig.landingContent && getSearchParam('product')) {
      track({
        eventName: 'opened.search-landing',
        logLevel: 'info',
        presearchProduct: brandConfig.brand.productTypesOrder[0],
      });
    }
  }, [brandConfig.landingContent]);

  if (!brandConfig.landingContent) {
    return null;
  }

  const { hero, productExplanation } = brandConfig.landingContent;
  const offers = brandConfig.offers;

  return (
    <>
      <DocumentTitle title={brandConfig.brand.pageTitle} />
      <Header />
      <Hero
        title={hero.title}
        subtitle={hero.subtitle}
        backgroundUrl={hero.backgroundUrl}
      />
      {productExplanation && (
        <TravelSearchInfo
          title={productExplanation.title}
          subtitle={productExplanation.subtitle}
          description={productExplanation.description}
        />
      )}
      {offers && (
        <React.Fragment>
          <Offers />
          <FavouriteDestinations />
        </React.Fragment>
      )}
      <Footer />
    </>
  );
}
