import { Step, UserObjectMap } from '../types/MappingTypes';

export function getUserInfo<T extends Step>({
  step,
  currency,
  deviceType,
  accountId,
  hashedEmail,
}: {
  step: T;
  currency: string;
  deviceType: 'mobile' | 'tablet' | 'desktop';
  accountId?: string;
  hashedEmail?: string;
}): UserObjectMap[T] {
  const basicUser = {
    currency,
    deviceType,
  };

  switch (step) {
    case 'hp':
      return {
        ...basicUser,
      } as UserObjectMap['hp'];
    case 'results':
      return {
        ...basicUser,
      } as UserObjectMap['results'];
    case 'details':
      return undefined;
    case 'payment':
      return {
        ...basicUser,
        accountId,
      } as UserObjectMap['payment'];
    case 'thankYou':
      return {
        ...basicUser,
        hashedEmail,
      } as UserObjectMap['thankYou'];
    default:
      return basicUser;
  }
}
