import useExternalScripts from '@/hooks/useExternalScripts';
import { useGetFlag } from '@/hooks/useGetFlag';
import { useStore } from '@/store/useStore';
import { useEffect } from 'react';

export const LmnTealium = () => {
  const { addScript } = useExternalScripts();
  const featureToggle = useGetFlag('travel.tracking.lmn_tealium');
  const [brandConfig] = useStore((s) => [s.brandConfig]);
  const brandBase = brandConfig?.brand?.brandBase?.toLowerCase();
  const isLmnCustomer = brandConfig?.brand?.brandOrigin === 'Lastminute';
  const environment = brandConfig?.environment?.toLowerCase();

  useEffect(() => {
    if (isLmnCustomer && featureToggle) {
      window.utag_data = {};

      addScript({
        url: `//tags.tiqcdn.com/utag/lastminutegroup/${brandBase}/${environment}/utag.js`,
        attributes: {
          type: 'text/javascript',
          async: 'true',
        },
      });
    }
  }, [brandConfig]);

  return undefined;
};
