import { useGetTravellerGroups } from '@/hooks/useGetTravellerGroups';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { AddCircle } from '@mui/icons-material';
import { ClickAwayListener, TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, useEffect, useState } from 'react';
import { Modal } from '../Modal';
import * as Styled from './PassengerSelector.styled';
import { PassengersSelectorProps } from './PassengerSelector.types';
import { Room } from './components/Room';

const MAX_AMOUNT_ROOMS = 6;
const MAX_AMOUNT_TRAVELLERS = 9;

export const PassengersSelector: FC<PassengersSelectorProps> = ({
  roomsState,
  productType,
}) => {
  const [rooms, setRooms] = roomsState;

  const [open, setOpen] = useState(false);

  const [addedDefaultRooms, setAddedDefaultRooms] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { t } = useTranslate();

  const prevTravellersGroup = useGetTravellerGroups();

  const brandConfig = useStore((state) => state.brandConfig);

  const { primaryColor } = brandConfig.theme;

  const { isMobile } = useIsMobile();

  const track = useTrack();

  useEffect(() => {
    if (!addedDefaultRooms) {
      prevTravellersGroup.rooms.length > 0 &&
        setRooms(prevTravellersGroup.rooms);
      setAddedDefaultRooms(true);
    }
  }, [prevTravellersGroup]);

  const peopleSum = rooms.reduce((acc, curr) => {
    acc = acc + curr.adults;
    acc = acc + curr.childrenAges.length;
    return acc;
  }, 0);

  const getResultString = (searchType: string) => {
    switch (searchType) {
      case 'F':
        return `${peopleSum} ${(peopleSum > 1
          ? t('general.passengers')
          : t('general.passenger')
        ).toLowerCase()}`;
      case 'DP':
        return `${peopleSum} ${(peopleSum > 1
          ? t('general.travellers')
          : t('general.traveller')
        ).toLowerCase()}, ${rooms.length} ${(rooms.length > 1
          ? t('search.rooms')
          : t('search.room')
        ).toLowerCase()}`;
      case 'H':
        return `${peopleSum} ${(peopleSum > 1
          ? t('general.guests')
          : t('general.guest')
        ).toLowerCase()}, ${rooms.length} ${(rooms.length > 1
          ? t('search.rooms')
          : t('search.room')
        ).toLowerCase()}`;
      default:
        return '';
    }
  };

  const getLabel = (searchType: string) => {
    switch (searchType) {
      case 'F':
        return t('search.passengers');
      case 'DP':
        return t('search.travellers');
      case 'H':
        return t('search.guests');
      default:
        return '';
    }
  };

  const handleAdult = (number: number, roomNumber: number) => {
    const result = rooms.map((room, index) =>
      index === roomNumber ? { ...room, adults: number } : room
    );

    setRooms(result);
  };

  const handleChildren = (age: number, roomNumber: number) => {
    const result = rooms.map((room, index) =>
      index === roomNumber
        ? { ...room, childrenAges: room.childrenAges.concat(age) }
        : room
    );

    setRooms(result);
  };

  const removeChild = (roomNumber: number, childIndex: number) => {
    const result = rooms.map((room, roomIndex) => {
      if (roomIndex === roomNumber) {
        const resultArray = room.childrenAges.filter(
          (_, index) => index !== childIndex
        );
        return { ...room, childrenAges: resultArray };
      }
      return room;
    });
    setRooms(result);
  };

  const addRoom = () => {
    rooms.length < MAX_AMOUNT_ROOMS &&
      setRooms(rooms.concat([{ adults: 1, childrenAges: [] }]));
  };

  const removeRoom = (roomNumber: number) => {
    const result = rooms.filter((_, index) => index !== roomNumber);
    setRooms(result);
  };

  const canAddPassengers =
    rooms.reduce((acc, curr) => {
      const adults = acc + curr.adults;
      const children = curr.childrenAges.length;
      acc = adults + children;
      return acc;
    }, 0) < MAX_AMOUNT_TRAVELLERS;

  const canAddRoom = rooms.length < 6 && canAddPassengers;

  const handleOpenModal = () => {
    track({
      eventName: 'opened.dates-selector',
      logLevel: 'info',
      presearchProduct: productType,
    });
    if (isMobile) {
      setIsModalOpen(true);
      setOpen(false);
    } else {
      setOpen(!open);
    }
  };

  const handleCloseSelector = () => {
    if (isMobile) {
      setIsModalOpen(false);
    } else {
      setOpen(false);
    }
    track({
      eventName: 'selected.rooms',
      logLevel: 'info',
      eventProperties: {
        rooms,
      },
      presearchProduct: productType,
    });
  };

  return (
    <Styled.PassengerSelector>
      <TextField
        value={getResultString(productType)}
        slotProps={{ inputLabel: { shrink: true } }}
        sx={{
          width: '100%',
          '& label': {
            fontSize: '18px',
          },
          '& input': {
            textOverflow: 'ellipsis',
          },
        }}
        onClick={handleOpenModal}
        label={getLabel(productType)}
      />
      {open && (
        <ClickAwayListener
          children={
            <Styled.PassengersSelectorContent $isMobile={isMobile}>
              {rooms.map((_, roomNumber, arr) => (
                <Room
                  key={roomNumber}
                  roomNumber={roomNumber}
                  adults={rooms[roomNumber].adults}
                  minors={rooms[roomNumber].childrenAges}
                  updateAdults={(number) => handleAdult(number, roomNumber)}
                  updateChild={(age) => handleChildren(age, roomNumber)}
                  removeChild={removeChild}
                  addRoom={() => addRoom()}
                  removeRoom={() => removeRoom(roomNumber)}
                  canAddRoom={canAddRoom}
                  canAddPassengers={canAddPassengers}
                  roomsAmount={arr.length}
                />
              ))}
            </Styled.PassengersSelectorContent>
          }
          onClickAway={() => setOpen(false)}
        />
      )}
      <Modal
        title={t('search.travellers')}
        isModalOpen={isModalOpen}
        fullHeight
        onCloseModal={handleCloseSelector}
        closable
      >
        <Styled.MobileRoomsContainer>
          <Styled.PassengersSelectorContent $isMobile={isMobile}>
            {rooms.map((_, roomNumber, arr) => (
              <Room
                key={roomNumber}
                roomNumber={roomNumber}
                adults={rooms[roomNumber].adults}
                minors={rooms[roomNumber].childrenAges}
                updateAdults={(number) => handleAdult(number, roomNumber)}
                updateChild={(age) => handleChildren(age, roomNumber)}
                removeChild={removeChild}
                addRoom={() => addRoom()}
                removeRoom={() => removeRoom(roomNumber)}
                canAddRoom={canAddRoom}
                canAddPassengers={canAddPassengers}
                roomsAmount={arr.length}
              />
            ))}
          </Styled.PassengersSelectorContent>
          {canAddRoom && isMobile && (
            <Styled.AddRoomMobile>
              <Styled.AddRoomMobileButton
                style={{
                  color: canAddPassengers ? primaryColor : '#3c3c3c',
                }}
                onClick={addRoom}
              >
                <AddCircle />
                {t('search.add.room')}
              </Styled.AddRoomMobileButton>
            </Styled.AddRoomMobile>
          )}
        </Styled.MobileRoomsContainer>
      </Modal>
    </Styled.PassengerSelector>
  );
};
