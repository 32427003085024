import { OutboundTransport } from '@/services/codegen-romulo';

export const getDetailedTransportEndpoints = (
  transport: Array<OutboundTransport> | null | undefined
) => {
  if (!transport?.length) return null;

  const allSegments = transport.flatMap((t) => t.segments || []);

  return {
    origin: allSegments[0],
    destination: allSegments[allSegments.length - 1],
    allStops: allSegments.map((segment) => ({
      departure: segment,
      destination: segment,
    })),
  };
};
