import {
  CartDetailResponseDto,
  PricingResponseDto,
} from '@/services/codegen-romulo';
import { getPricingAccommodation } from './getPricingAccommodation';
import { getPricingTrip } from './getPricingTrip';
import { ProductObjectMap } from '../types/MappingTypes';
import { calculateTripDuration } from './calculateTripDuration';
import dayjs from 'dayjs';
import { getFlightTrip } from './getFlightTrip';

export function getProductInfo<T extends 'details' | 'payment' | 'thankYou'>({
  step,
  pricingProposals,
  cartDetail,
  numberOfTravellers,
  bookingType,
}: {
  step: T;
  pricingProposals: PricingResponseDto;
  cartDetail: CartDetailResponseDto;
  numberOfTravellers: {
    adultNumber: number;
    childrenNumber: number;
    infantNumber: number;
    allChildrenAges: number[];
    numberOfRooms: number;
  };
  bookingType: string;
}): ProductObjectMap[T] {
  const flightItinerary =
    bookingType === 'Flight'
      ? cartDetail.products.find((e) => e.type === 'FLIGHT')
      : undefined;

  const numberOfGuests =
    numberOfTravellers.adultNumber +
    numberOfTravellers.childrenNumber +
    numberOfTravellers.infantNumber;
  const staticData =
    bookingType !== 'Flight' ? pricingProposals.staticData : undefined;

  const mealPlan =
    bookingType !== 'Flight'
      ? cartDetail?.products?.find((p) => p.type === 'HOTEL').detail
          .accommodation.rooms[0].mealPlanName
      : undefined;

  const productBasicInfo = {
    infants: numberOfTravellers.infantNumber,
    name: '', // TODO We don't have some info
    numberOfGuests,
    accomodation:
      bookingType !== 'Flight' && pricingProposals?.proposal?.accommodation
        ? getPricingAccommodation({
            step,
            staticData,
            numberOfRooms: numberOfTravellers.numberOfRooms,
            startDate: pricingProposals?.proposal?.accommodation.checkin,
            endDate: pricingProposals?.proposal?.accommodation.checkout,
            mealPlan,
          })
        : undefined,
    trip:
      bookingType === 'FlightAndHotel' && pricingProposals?.proposal?.transport
        ? getPricingTrip({
            step,
            staticData,
            transport: pricingProposals.proposal.transport,
            cartDetail,
          })
        : bookingType === 'Flight' && step !== 'details'
          ? getFlightTrip({
              step,
              cartDetail,
            })
          : undefined,
  };

  const cat =
    bookingType === 'FlightAndHotel'
      ? 'flightHotel'
      : bookingType === 'Flight'
        ? 'flight'
        : bookingType === 'Hotel'
          ? 'hotel'
          : '';

  const productPaymentExtra = {
    adults: numberOfTravellers.adultNumber,
    advancedBooking: calculateTripDuration(
      dayjs().format(),
      bookingType !== 'Flight'
        ? pricingProposals?.proposal?.accommodation.checkin
        : flightItinerary.detail.itinerary.outboundTransport[0].segments[0]
            .departureDateTime
    ),
    category: cat,
    children: numberOfTravellers.childrenNumber,
    offerType: pricingProposals?.staticData?.promotions[0]?.type.toLowerCase(),
    priceUserCurrency:
      bookingType !== 'Flight'
        ? cartDetail?.products
            ?.find((p) => p.type === 'HOTEL')
            .detail?.accommodation.cost.cost.value.toString()
        : undefined,
    sku: '', // TODO We don't have some info
  };

  switch (step) {
    case 'details':
      return {
        ...productBasicInfo,
      };
    case 'payment':
    case 'thankYou':
      return {
        ...productBasicInfo,
        ...productPaymentExtra,
      };
    default:
      return {
        ...productBasicInfo,
      };
  }
}
