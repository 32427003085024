import { useTrack } from '@/hooks/useTrack';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import { AirportSearch } from './components/AirportSearch/AirportSearch';
import { DestinationSearch } from './components/DestinationSearch/DestinationSearch';
import { ResultElementProps } from './components/SearchInput/ResultElement';
import { useGetDestinations } from './hooks/useGetDestinations';
import * as Styled from './LocationSelector.styled';
import { ProductType } from '../../Search.types';

interface LocationSelectorProps {
  setDeparture: React.Dispatch<React.SetStateAction<ResultElementProps>>;
  setDestination: React.Dispatch<React.SetStateAction<ResultElementProps>>;
  setHotel: React.Dispatch<React.SetStateAction<ResultElementProps>>;
  missingDeparture?: boolean;
  missingDestination?: boolean;
  productType?: string;
}

const LocationSelector: FC<LocationSelectorProps> = ({
  setDeparture,
  setDestination,
  setHotel,
  missingDeparture,
  missingDestination,
  productType,
}) => {
  const track = useTrack();

  const { t } = useTranslate();

  const getDestinations = useGetDestinations();

  const handleSetDeparture = (e: ResultElementProps | undefined) => {
    setDeparture(e);
    if (e) {
      track({
        eventName: 'selected.departure',
        logLevel: 'info',
        eventProperties: {
          title: e?.title,
        },
        presearchProduct: productType,
      });
    }
  };

  const handleSetDestination = async (e: ResultElementProps | undefined) => {
    try {
      if (e.type === 'Hotel') {
        setHotel(e);
        if (e.additionalInfo) {
          const city = e.additionalInfo.split(',')[0];
          const basedCity = await getDestinations(city);
          setDestination(basedCity[0]);
        }
      } else {
        setDestination(e);
      }
    } catch (error) {
      setDestination(e);
    }

    if (e) {
      track({
        eventName: 'selected.destination',
        logLevel: 'info',
        eventProperties: {
          title: e?.title,
        },
        presearchProduct: productType,
      });
    }
  };

  const handleOnClickInput = (direction: string) => {
    track({
      eventName: `clicked.open-${direction}`,
      logLevel: 'info',
      presearchProduct: productType,
    });
  };

  switch (productType) {
    case ProductType.F:
      return (
        <Styled.Container>
          <AirportSearch
            onChange={handleSetDeparture}
            label={t('search.departure')}
            handleOnClickInput={() => handleOnClickInput('departure')}
            placeholder={t('search.departure.placeholder')}
            error={missingDeparture && t('search.departure.error')}
            direction={'OUTBOUND'}
          />
          <Styled.Divider />
          <AirportSearch
            onChange={handleSetDestination}
            handleOnClickInput={() => handleOnClickInput('destination')}
            label={t('search.destination')}
            placeholder={t('search.destination.placeholder')}
            error={missingDestination && t('search.destination.error')}
            direction={'INBOUND'}
          />
        </Styled.Container>
      );

    case ProductType.DP:
      return (
        <Styled.Container>
          <AirportSearch
            onChange={handleSetDeparture}
            handleOnClickInput={() => handleOnClickInput('departure')}
            label={t('search.departure')}
            placeholder={t('search.departure.placeholder')}
            error={missingDeparture && t('search.departure.error')}
            direction={'OUTBOUND'}
          />
          <Styled.Divider />
          <DestinationSearch
            onChange={handleSetDestination}
            handleOnClickInput={() => handleOnClickInput('destination')}
            label={t('search.destination')}
            placeholder={t('search.destination.placeholder')}
            error={missingDestination && t('search.destination.error')}
          />
        </Styled.Container>
      );
    case ProductType.H:
      return (
        <DestinationSearch
          onChange={handleSetDestination}
          handleOnClickInput={() => handleOnClickInput('destination')}
          label={t('search.destination')}
          placeholder={t('search.destination.placeholder')}
          error={missingDestination && t('search.destination.error')}
        />
      );

    default:
      return null;
  }
};

export { LocationSelector };
