import { version } from '@/version';
import { PageObjectMap, Step } from '../types/MappingTypes';
import { pagePlatformName } from './commonValues';
import { getSection } from './getSection';

export function getPageInfo<T extends Step>({
  step,
  brandBase,
  bookingType,
  locale,
}: {
  step: T;
  brandBase: string;
  bookingType: string;
  locale: string;
}): PageObjectMap[T] {
  const basicPage = {
    brand: brandBase,
    category: bookingType,
    locale: locale.toLowerCase(),
    platformName: pagePlatformName,
    platformVersion: version,
    section: getSection({ step, bookingType }),
    type: step,
  };

  const categoryLevelCode = step !== 'hp' && undefined; // TODO NEEDED We don't have this info
  const pBackUrl =
    (step === 'payment' || step === 'thankYou') &&
    sessionStorage.getItem('pBackUrl');

  switch (step) {
    case 'hp':
      return {
        ...basicPage,
        cluster: 'productHp',
      } as PageObjectMap['hp'];
    case 'results':
      return {
        ...basicPage,
        categoryLevelCode,
      } as PageObjectMap['results'];
    case 'details':
      return {
        ...basicPage,
        categoryLevelCode,
      } as PageObjectMap['details'];
    case 'payment':
      return {
        ...basicPage,
        categoryLevelCode,
        pBackUrl,
      } as PageObjectMap['payment'];
    case 'thankYou':
      return {
        ...basicPage,
        categoryLevelCode,
        pBackUrl,
      } as PageObjectMap['thankYou'];
    default:
      return basicPage;
  }
}
