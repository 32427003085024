import { useStore } from '@/store/useStore';
import { getCookieValue } from '@/store/utils/authenticateExternal/getCookieValue';
import mixpanel from 'mixpanel-browser';
import useFingerprint from './useFingerprint';
import { useGetParam } from './useGetParam';

interface eventSchema {
  eventName: string;
  eventSubName?: string;
  logLevel: 'warn' | 'log' | 'error' | 'info' | 'none';
  eventProperties?: object;
  eventData?: object;
  userProperties?: object;
  contextProperties?: object;
}

interface TrackOptions {
  omitEventR?: boolean;
  omitMixPanel?: boolean;
  omitSentry?: boolean;
  presearchProduct?: string | null;
}

const PROJECT_NAME = 'travel-ui';
const LMN_MEASUREMENT_COOKIE_KEY = '5';

const url = window._env_.VITE_EVENT_R_URL;

async function eventR(event: eventSchema) {
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Special-Header': window._env_.VITE_EVENT_R_TOKEN,
    },
    body: JSON.stringify(event),
  });
}

export const useTrack = () => {
  const [brandConfig, externalAuth] = useStore((state) => [
    state.brandConfig,
    state.externalAuth,
  ]);
  const getParam = useGetParam();
  const product = getParam('product');
  const { fingerprint, sessionFingerprint } = useFingerprint();

  const clientData = externalAuth?.clientData;

  const acceptedCookies = getCookieValue('cookies-acceptance')?.split(',');
  const lmnAcceptedCookies = getCookieValue('oil_GTM_cookie');
  const lmnAcceptedCookiesParsed =
    lmnAcceptedCookies ?? JSON.parse(lmnAcceptedCookies);

  const hasMeasurementCookie = acceptedCookies?.includes('measurement');
  const lmnHasMeasurementCookie =
    lmnAcceptedCookiesParsed?.[LMN_MEASUREMENT_COOKIE_KEY] === true;

  const track = ({
    eventName,
    eventSubName,
    logLevel,
    eventProperties,
    eventData,
    userProperties,
    contextProperties,
    omitEventR = false,
    omitMixPanel = false,
    omitSentry = false,
    presearchProduct = null,
  }: eventSchema & TrackOptions) => {
    const eventObject = {
      eventSubName,
      logLevel,
      eventProperties,
      eventData: {
        fingerprint:
          hasMeasurementCookie || lmnHasMeasurementCookie ? fingerprint : null,
        sessionFingerprint: sessionFingerprint,
        externalUserId: clientData?.userId || null,
        brand: brandConfig?.brand?.businessClient,
        subBrand: brandConfig?.brand?.brandName,
        product: presearchProduct ?? product,
        ...eventData,
      },
      userProperties,
      contextProperties,
      project: PROJECT_NAME,
    };

    if (!omitMixPanel) {
      try {
        mixpanel?.track(eventName, eventObject);
        // fire and forget
      } catch (error) {}
    }

    if (!omitEventR) {
      try {
        eventR({ eventName, ...eventObject });
        // fire and forget
      } catch (error) {}
    }

    if (!omitSentry) {
      // SentryTrack
    }
  };

  return track;
};
