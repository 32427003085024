export function getDeviceType() {
  const userAgent = navigator.userAgent;

  if (/mobile/i.test(userAgent)) {
    return 'mobile';
  }

  if (/tablet/i.test(userAgent)) {
    return 'tablet';
  }

  return 'desktop';
}
