import { useStore } from '@/store/useStore';
import { isEmpty } from '@/utils/isEmpty';
import { useCurrencyFormatter } from '@/views/Checkout/hooks/useCurrencyFormatter';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './FavouriteDestinations.styled';

export const FavouriteDestinations: FC = () => {
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { primaryColor } = brandConfig.theme;
  const { t } = useTranslate();
  const offers = brandConfig.offers;
  const currencyFormatter = useCurrencyFormatter();

  const destinationList = offers?.destinationList;

  if (isEmpty(destinationList)) {
    return null;
  }
  return (
    <Styled.FlashSaleOffersContainer>
      <Styled.InnerContainer>
        <Styled.TitleContainer>
          <div>
            <Styled.SectionTitle>
              {t(offers.favouriteDestinationsTitleKey)}
            </Styled.SectionTitle>
            <Styled.Subtitle>
              {t(offers.favouriteDestinationsSubtitleKey)}
            </Styled.Subtitle>
          </div>
        </Styled.TitleContainer>
        <Styled.OffersCarousel>
          {destinationList.map((e, key) => (
            <Styled.DestinationLink href={e.url} key={key}>
              <Styled.DestinationContainer>
                <Styled.DestinationImage
                  url={e?.image?.data?.attributes?.url || ''}
                />
                <Styled.DetinationContent>
                  <Styled.Name>{e.destinationName}</Styled.Name>
                  <Styled.Tag color={primaryColor}>
                    {t('general.price.from')}{' '}
                    {currencyFormatter.formatNumber(e.fromPrice)}
                  </Styled.Tag>
                </Styled.DetinationContent>
              </Styled.DestinationContainer>
            </Styled.DestinationLink>
          ))}
        </Styled.OffersCarousel>
      </Styled.InnerContainer>
    </Styled.FlashSaleOffersContainer>
  );
};
